// Base theme styles.
@import '../../steindom_theme/less/base';

// Variables.
@import 'variables';

// Things to hide.
.pane-page-image {
  display: none;
}

// Text.
.bigger {
  font-size: 18px;
  line-height: 1.2;
  @media (min-width: @screen-sm-min) {
    font-size: 18px;
  }
  margin-bottom: 12px;
}

// Buttons.
a.btn {
  transition: all 300ms;
  &:hover, &:focus {
    transform: translateX(8px);
  }
}

// White button links
.dark a.btn-link {
  color: #FFF;
  border: 1px solid #FFF;
  border-radius: 0;
  transition: all 300ms;
  margin-top: 10px;
  &:hover {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);
  }
}

// Light outline buttons.
.btn-outline-light {
  .button-variant(white; transparent; white);
  &:hover {
    color: dimgrey;
    background-color: white;
    border-color: white;
  }
}

// Header.
.front .page-header .pane-page-logo a img:hover {
  opacity: 1;
  cursor: default;
}
.pane-page-site-name {
  font: bold 2.5vw/1.1 @font-family-sans-serif;
  margin-bottom: 0;
  @media @mobile-only {
    font-size: 24px;
  }
  @media @tablet-only {
    margin-bottom: 12px;
  }
  @media @desktop {
    margin-bottom: 20px;
  }
  body.with-logo & {
    .sr-only();
  }
}
.pane-page-slogan {

}
.page-header {
  border-bottom: 4px solid #FFF;
  background-size: cover;
  background-position: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
  color: #FFF;
  // @media (max-width: 767px) {
  //   background-size: auto 100vh !important;
  //   background-position: top;
  // }
  .overlay {
    padding-top: 18px;
    @media (max-width: 767px) {
      padding-top: 8px;
    }
    background-image: linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.10) 100%);
  }
  .pane-content {
    a {
      color: #FFF;
    }
  }
  .pane-menu-quick-links {
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    .menu-name-menu-quick-links a {
      transition: all 300ms;
      transform: scale(1, 1);
      display: block;
      border-bottom: 1px solid transparent;
      font-size: 13px;
      @media (max-width: 767px) {
        font-size: 11px;
        line-height: 12px;
      }
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 5px;
      &.active {
        opacity: 0.5;
        &:hover {
          transform: none;
          border-bottom: 0;
          cursor: default;
        }
      }
      &:hover {
        transform: scale(1.1, 1.1);
        text-decoration: none;
        border-bottom: 1px solid #FFF;
      }
    }
  }
  .top {
    position: relative;
    display: flex;
    > div {
      flex-grow: 1;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .pane-page-logo {
      max-width: @theme-logo-max-width;
    }
    @media (max-width: 767px) {
      .branding {
        width: 60vw;
      }
    }
    .menus {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 767px) {
        align-items: center;
        flex-direction: column-reverse;
        margin-top: 1.5rem;
      }
      .sf-main-menu.sf-horizontal {
        margin-left: 20px;
        margin-right: -16px;
        a {
          font: bold @theme-main-menu-font-size/1.1 @main-menu-font-family;
          text-transform: uppercase;
          padding: 8px 16px;
          border: 1px dotted transparent;
          &:hover, &:focus {
            text-decoration: none;
          }
          &.active {
            border-color: #FFF;
            &:hover {
              transform: none;
              cursor: default;
            }
          }
        }
        li.sf-depth-1.last a {
          color: @theme-main-menu-last-link-color;
        }
        li.sf-depth-1:hover,
        li.sf-depth-1:focus,
        li.sf-depth-1.sfHover {
          background: @main-menu-bg-color;
        }
        li.sf-depth-1.active-trail > a {
          border-color: #FFF;
        }
        li.sf-depth-2 a {
          background: @main-menu-bg-color;
          font-size: (@main-menu-font-size * .75);
          &:hover, &:focus {
            background: lighten(@main-menu-bg-color, 5%);
          }
        }
      }
      .search-social-wrapper {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          position: absolute;
          top: -5px;
          left: 62vw;
          width: 34vw;
          flex-direction: column;
          .pane-hosted-search-form {
            margin-bottom: 0;
          }
          .form-group {
            margin-bottom: 5px;
          }
        }
        @media (min-width: 768px) {
          padding-top: 30px;
        }
        #hosted-search-form {
          max-width: 180px;
          display: inline-block;
          padding-right: 20px;
          input[type=text] {
            border: none;
            box-shadow: none;
            border-bottom: 2px solid #FFF;
            border-radius: 0;
            color: #FFF;
            background: transparent;
            font-size: 18px;
            @media (max-width: 767px) {
              font-size: 14px;
              border-bottom: 1px solid #FFF;
            }
            &::placeholder {
              color: #FFF;
              font-size: 18px;
              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
        }
        .icon-set-hosted_social_links a img {
          width: 25px;
          @media (max-width: 767px) {
            width: 20px;
          }
          transform: scale(1, 1);
          transition: all 300ms;
          &:hover {
            transform: scale(1.2, 1.2) translateY(-5px);
            opacity: 1;
          }
        }
        @media (max-width: 767px) {
          .pane-social-media-link-widget .horizontal li {
            padding-right: 0;
          }
        }
      }
    }
  }
  h1.page-title {
    color: #FFF;
    text-align: center;
    font: bold 4vw/1.1 @font-family-sans-serif;
    padding: 4vw 0 10vw 0;
    @media (max-width: 767px) {
      font-size: 30px;
      padding: 0 0 20px;
    }
  }
}

// Page Content.
.page-content {
  background: #FFF;
  padding: 40px 0 12px;
  position: relative;
  z-index: 0;
}

// Footer.
.page-footer {
  padding: 40px 0 12px;
  .pane-content {
    color: #FFF;
    a:not(.btn) {
      color: #FFF;
      text-decoration: underline;
      transition: all 300ms;
      &:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }
}


// Call to Action.
.call-to-action {
  padding: 13vw 60px;
  h3.call-to-action__title {
    font-size: 3vw;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .call-to-action__text {
    font-size: 2vw;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .call-to-action__link .btn-primary {
    background:  transparent;
    border:  1.3px solid #FFF;
    border-radius: 0;
    font-size: 18px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5);
    transition: all 300ms;
    &:hover, &:focus {
      background: @brand-primary;
    }
  }
}

// Pane styles.
.pane-style-style-attention,
.pane-style-style-alternate,
.pane-style-style-info {
  padding: 22px 30px;
  border: 1px solid #FFF;
  .pane-title {
    margin-top: 0;
    color: #FFF;
  }
  .pane-content {
    color: #FFF;
    a:not(.btn) {
      color: #FFF;
      text-decoration: underline;
      transition: opacity 300ms;
      &:hover, &:focus {
        text-decoration: none;
        opacity: 0.5;
      }
    }
  }
}

.pane-style-style-info {
  background: @theme-pane-style-info-bg-color;
}

.pane-style-style-attention {
  background: @theme-pane-style-attention-bg-color;
}

.pane-style-style-subdued {
  padding: 20px;
  background: @theme-pane-style-subdued-bg-color;
  .pane-title {
    margin-top: 0;
  }
}

.pane-style-style-alternate {
  background: @theme-pane-style-alternate-bg-color;
}



// Mobile Main Menu.
.sf-accordion-toggle {
  background: @main-menu-mobile-bg-color;
  a {
    display: block;
    min-width: 90vw;
    padding: @main-menu-mobile-toggle-link-padding;
    text-align: left;
    text-transform: uppercase;
    text-decoration: none;
    color: @main-menu-mobile-toggle-link-color;
    background-color: @main-menu-mobile-toggle-bg-color;
    & when not (@theme-main-menu-text-shadow = false) {
      text-shadow: @theme-main-menu-text-shadow;
    }
    &:hover, &.sf-expanded {
      color: @main-menu-mobile-toggle-active-link-color;
      background-color: @main-menu-mobile-toggle-active-bg-color;
    }
    &:before {
      .glyphicon();
      content: '\e236';
      margin-right: 5px;
    }
  }
}
.sf-accordion {
  a {
    padding: 10px;
    color: @main-menu-mobile-link-color;
    background-color: @main-menu-mobile-link-bg-color;
    border-top: 1px solid @main-menu-mobile-link-border-color;
    text-decoration: none;
    &.menuparent:after {
      content: @main-menu-arrow-right;
      font-family: 'Glyphicons Halflings';
      margin-left: .5em;
      font-size: .625em;
      line-height: 1;
      & when not (@theme-main-menu-text-shadow = false) {
        text-shadow: none;
      }
    }
  }
  li.sf-depth-1 > a {
    & when not (@theme-main-menu-text-shadow = false) {
      text-shadow: @theme-main-menu-text-shadow;
    }
    & when (@main-menu-uppercase) {
      text-transform: uppercase;
    }
  }
  .sf-expanded > .menuparent:after {
    content: @main-menu-arrow-down;
  }
  .sf-clone-parent .menuparent:after {
    display: none;
  }
  ul a {
    padding-left: 20px;
    color: @main-menu-sublinks-color;
    background-color: @main-menu-mobile-sublinks-bg-color;
    & when not (@main-menu-sublinks-font-family = @main-menu-font-family) {
      font-family: @main-menu-sublinks-font-family;
    }
    & when not (@main-menu-sublinks-text-shadow = false) {
      text-shadow: @main-menu-sublinks-text-shadow;
    }
  }
  ul ul a {
    padding-left: 30px;
    background-color: @main-menu-mobile-subsublinks-bg-color;
  }
  ul ul ul a {
    padding-left: 40px;
    background-color: @main-menu-mobile-subsubsublinks-bg-color;
  }
}



// Blog posts listing.
.view-blog-posts-listing-item {
  border: 1px solid #ccc;
  padding: 12px;
  position: relative;
  background: #FFF;
  img {
    transition: all 300ms;
  }
  &:hover, &:focus {
    img {
      transform: scale(1.3, 1.3);
    }
  }
  .views-field-field-blog-post-image {
    margin: -12px -12px 16px -12px;
    overflow: hidden;
  }
  .title a {
    color: inherit;
  }
  a.more-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
  &:before,
  &:after {
    content: ' ';
    position: absolute;
    bottom: 10px;
    width: 40%;
    height: 10px;
    box-shadow: 0 8px 15px rgba(0,0,0,.9);
    z-index: -1;
  }

  &:before {
    left: 10px;
    transform: skew(-5deg) rotate(-5deg);
  }

  &:after {
    right: 10px;
    transform: skew(5deg) rotate(5deg);
  }
}

// Blog posts.
.panel-pane.pane-node-field-blog-post-subtitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}
.field-name-field-blog-post-body {
  font-size: 18px;
  margin-bottom: 3em;
}

// Shared pane styles.
.pane-style(@bg-color) {
  background-color: @bg-color;
  padding: 20px;

  > .pane-title {
    .fix-colors(@bg: @bg-color, @text: @text-color, @headings: false, @underline: false);
    margin-top: 0;
    padding: 0 10px 15px;

    &:before when (luma(@bg-color) < 50%) and (alpha(@bg-color) > .5) and (luma(@text-color) < 50%) {
      border-bottom: 2px solid white;
    }
  }

  > .pane-content {
    .fix-colors(@bg: @bg-color, @text: @text-color);
    font-size: 15px;
  }
}

// Submenu.
.pane-submenu {
  .pane-style(@theme-submenu-bg-color);

  > .pane-title {
    text-align: left;
    border-bottom: 2px solid white;
    &:before {
      display: none;
    }
  }

  > .pane-content {
    .submenu-arrows(@collapsed: '\e250', @expanded: '\e252');
    .submenu-font-size-tier();

    a {
      font-size: 18px;
      padding: 0.4em 0.4em;
      .opacity-hover(.6);
      text-decoration: none !important;
      &.active {
        opacity: .4;
        backface-visibility: hidden;
      }
    }

    li.submenu-parent:before {
      margin-top: 0.6em;
    }
  }
}

// Credit.
.page-credit {
  margin: 20px 0;
  .panel-pane {
    margin-bottom: 0;
  }
  .pane-content {
    font-family: @font-family-sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: white;
    .opacity-hover(1, .6);
    a {
      text-decoration: none;
      color: inherit;
    }
    &:hover a {
      text-decoration: underline;
    }
  }
}

